import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';
import React from 'react';
import { getCompanyFn } from 'modules/companyPreview/helpers';
import Seo from 'modules/app/components/Seo';
import { makeBaseImagePath } from 'modules/app';
import { CompanyPreviewRoot } from 'modules/companyPreview/CompanyPreviewRoot';

interface Props {
  serverData: {
    company?: PublicCompany;
    error?: ApiErrorData;
  };
}

const CompanyProfile: React.FC<Props> = ({ serverData }) => {
  const { siteUrl, description } = useSiteMetadata();
  const { company, error } = serverData;

  const metaImage = company?.metaImage
    ? makeBaseImagePath(company.metaImage)
    : `${siteUrl}/roango-share-image.png`;

  const companyTitle = company?.metaTitle
    ? company.metaTitle
    : company?.name || 'Company Overview - Roango';

  const companyDescription = company?.metaDescription
    ? company.metaDescription
    : company?.shortDescription || description;

  return (
    <>
      <Seo
        title={companyTitle}
        description={companyDescription}
        url={`${siteUrl}/company/${company?.handle}`}
        image={metaImage}
      />

      <CompanyPreviewRoot path="/*" company={company} error={error} />
    </>
  );
};

export async function getServerData(context: {
  params: Record<string, string>;
}) {
  const companyHandle = context.params.companyHandle;

  const { error, company } = await getCompanyFn(companyHandle);

  const data = {
    props: {
      error,
      company,
    },
    headers: {
      'Cache-Control': 'public, max-age=0, must-revalidate',
    },
  };

  if (!error) return data;

  return {
    status: error.status,
    ...data,
  };
}

export default CompanyProfile;
